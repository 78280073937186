import { useContext, useEffect, useState } from 'react'
import {
  GlobalStateContext,
  UserDispatchContext,
} from '../context/GlobalContextProvider'
import firebase from 'gatsby-plugin-firebase'
import { useObjectVal } from 'react-firebase-hooks/database'
import {
  bloomBAnonymousLogin,
  getLoggedInUsername,
  getUser,
  isAdministrator,
  isApprovedRole,
  isLoggedIn,
  setUserProfileLS,
} from '../../services/firebase'
import {
  allowDisallow,
  extractUid,
  getBitlyUrl,
  getDateFromTS,
  handleWebHook,
  isExpired,
  lowerFirstChar,
} from '../../utils'
import {
  BB_ACCESS_CODE_PREFIX,
  BB_ADMIN_USERNAME,
  BB_BASE_URL,
  BB_REGISTERED_DATE,
} from '../../utils/constants'
import Tabletop from 'tabletop'
import { navigate } from 'gatsby'

export const useMessage = () => {
  const globalStateContext = useContext<any>(GlobalStateContext)
  const dispatch = useContext<any>(UserDispatchContext)

  const [msg, setMsg] = useState({ type: '', text: '' })
  const dispatchReset = () => {
    setTimeout(() => {
      dispatch({ type: 'MESSAGE_RESET' })
    }, 8000)
  }
  useEffect(() => {
    if (globalStateContext?.message) {
      setMsg({
        ...msg,
        type: globalStateContext?.message.type,
        text: globalStateContext?.message.text,
      })
    }
  }, [globalStateContext])
  useEffect(() => {
    if (msg.text) {
      dispatchReset()
    }
  }, [msg])
  return [msg, setMsg]
}

export const useInitLogin = () => {
  const dispatch = useContext<any>(UserDispatchContext)
  const globalStateContext = useContext<any>(GlobalStateContext)

  const [done, setDone] = useState<Boolean>(false) //to avoid infinite renders

  const [data, isLoading] = useObjectVal<any>(
    firebase.database().ref(`users/${globalStateContext.user.uid}`)
  )
  const [userData, setUserData] = useState({})
  useEffect(() => {
    if (data && !done && !isLoading) {
      dispatch({ type: 'SET_PROFILE', payload: data })
      setUserProfileLS(data)
      // setTimeout(() => {
      //   addBodyClass(BB_BODY_CLASS)
      // }, 1000)
      setDone(true)
    }
  }, [data])

  useEffect(() => {
    if (done) {
      const user = getUser()
      setUserData({
        displayName: user.displayName,
        username: user.accessProfile.username,
      })
    }
  }, [done])
  return [userData]
}

/* Hook for displaying different content for doctors, pharmacists, hr etc */
export const useApprovedContent = (roles: string[]) => {
  const [display, setDisplay] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      if (isLoggedIn() && (isApprovedRole(roles) || isAdministrator())) {
        setDisplay(true)
      }
    }, 500)
  }, [])
  return [display, setDisplay]
}

/* Please see the implementation as there is some tight coupling - if(item.val().hasOwnProperty('id')) {
  For table patients etc
  this works only if the object contains a key named id or updatedBy
  */
export const useSnapShotForTable = ({
  data,
  columns,
}: {
  data: any
  columns: string[]
}) => {
  const [datatableData, setDatatableData] = useState<string[][]>([])
  useEffect(() => {
    if (data?.length) {
      console.log('REQQQ', data?.length)
      let tableData: string[][] = []
      data?.map((item: any) => {
        console.log('REQQQKey', item.key)
        console.log('REQQQValue', item.val())
        let dt: string[] = []
        if (
          item.val().hasOwnProperty('id') ||
          item.val().hasOwnProperty('updatedBy')
        ) {
          columns.map(column => {
            if (column === BB_REGISTERED_DATE || column === 'start_time') {
              const dtVal = getDateFromTS(Number(item.key))
              console.log('DTE VAL', dtVal)
              return dt.push(dtVal)
            }

            dt.push(item.val()[lowerFirstChar(column)])
          })
          tableData.push(dt)
          setDatatableData(tableData)
        }
      })
    }
  }, [data])
  return [datatableData]
}

export const useLoggedInUser = () => {
  const [loggedInUsername, setLoggedInUsername] = useState('')
  useEffect(() => {
    setTimeout(() => {
      const loggedInUsername = getLoggedInUsername()
      if (loggedInUsername) {
        setLoggedInUsername(loggedInUsername)
      }
    }, 1000)
  }, [])
  return [loggedInUsername]
}

/* For multilevel databases such as appointments  */
export const useMultilevelSnapshotForTable = ({
  data,
  columns,
}: {
  data: any
  columns: string[]
}): string[][] => {
  const [datatableDataNew, setDatatableDataNew] = useState<string[][]>([])
  useEffect(() => {
    if (data?.length) {
      let tableData: string[][] = []
      data.map((item: any) => {
        console.log('TBLA ALFIE Key', item.key)
        console.log('TBLA ALFIE Val', item.val())

        const doctor = item.key
        Object.entries(item.val()).map(innerItem => {
          let dt: string[] = []
          dt.push(doctor)
          const regDate = getDateFromTS(Number(innerItem[0]))
          columns.map(column => {
            if (column === BB_REGISTERED_DATE) {
              dt.push(regDate)
            } else {
              if (column === 'start_time') {
                dt.push(getDateFromTS(innerItem[1][column]))
              } else {
                dt.push(innerItem[1][column])
              }
            }
          })
          tableData.push(dt)
        })
      })
      setDatatableDataNew(tableData)
      // console.log('ALFIE', tableData)
    }
  }, [data])
  return [datatableDataNew]
}

/* Hook for fetching data from tables such as patients, queue, users, status etc (which has one sublevel under reference eg : patients/datetime)*/
export const useSingleLevelFBRTRef = (data: any) => {
  const [queueData, setQueueData] = useState<any[]>([])
  useEffect(() => {
    if (data?.length) {
      let queueData: any[] = []
      data.map(item => {
        console.log('UUUU Key', item.key)
        console.log('UUUU Val', item.val())
        const object = { ...item.val(), key: item.key }
        queueData.push(object)
      })
      console.log('UUUU', queueData)
      setQueueData(queueData)
    }
  }, [data])
  return [queueData]
}

/* Filter hook for filtering an object from singlelevel FB RT reference such as patients etc 
const [patientsSnapshot, isPatientsLoading, patientsError] = useList(
    firebase.database().ref(`patients`)
  )
  const [patientsData] = useSingleLevelFBRTRef(patientsSnapshot)
  const [filteredData] = useSingleLevelFilter({
    data: patientsData,
    dataFilter: {
      key: 'id',
      value: data['Patient ID'],
    },
  })


*/
export const useSingleLevelFilter = ({
  data,
  dataFilter,
}: {
  data: any
  dataFilter: {
    key: string
    value: string | number
  }
}) => {
  const [filteredData, setFilteredData] = useState<any>()
  console.log('STF', filteredData)

  useEffect(() => {
    if (data.length && data) {
      const filteredData = data.filter(
        (item: any) => item[dataFilter.key] === dataFilter.value
      )
      setFilteredData(filteredData[0])
    }
  }, [data, dataFilter])
  return [filteredData]
}

/* Hook for fetching data from multi level references such as casehistory etc */
export const useMultiLevelFBRTRef = (data: any) => {
  const [multiLevelData, setMultiLevelData] = useState<any[]>([])
  useEffect(() => {
    if (data?.length) {
      let arrayObjectData: string[] = []
      data?.map((item: any) => {
        // console.log('CASEHIST Key', item.key)
        // console.log('CASEHIST Val', item.val())
        Object.entries(item.val()).map(innerItem => {
          const object = {
            ...innerItem[1],
            key: innerItem[0],
            parentKey: item.key,
          }
          arrayObjectData.push(object)
        })
      })
      setMultiLevelData(arrayObjectData)
    }
  }, [data])
  return [multiLevelData]
}

/* filter hook for extracting data from multi level data as obtained from above hook */
export const useMultiLevelFilter = ({
  data,
  dataFilter,
}: {
  data: any
  dataFilter: {
    key: string
    value: string
  }[]
}) => {
  const [filteredData, setFilteredData] = useState<any>([])
  console.log('123 STF', filteredData)

  useEffect(() => {
    if (data.length && data) {
      let filteredData: any[] = [...data]
      dataFilter.map((filterItem: any) => {
        filteredData = filteredData.filter(
          (item: any) => item[filterItem.key] === filterItem.value
        )
        console.log('123 CASEHIST NOW', filteredData)
      })
      console.log('123 CASEHIST FILT', filteredData)
      setFilteredData(filteredData[0])
    }
  }, [data, dataFilter])
  return [filteredData]
}

export const useGoogleSheet = (googleSheetKey: string) => {
  const [googleData, setGoogleData] = useState([])
  useEffect(() => {
    Tabletop.init({
      key: googleSheetKey,
      callback: googleData => {
        setGoogleData(googleData)
        console.log('google sheet data --->', googleData)
      },
      simpleSheet: true,
    })
  }, [])
  return [googleData]
}

/* Hook for using special access to public with anonymous login enabled */
export const useSpecialAccess = ({
  data,
  uniqueId,
}: {
  data: any
  uniqueId: string
}) => {
  const [uid, setUid] = useState('')

  useEffect(() => {
    const login = async () => {
      const response = await bloomBAnonymousLogin()
      console.log('PAYMENT LOG', response)
    }
    login()
  }, [])

  useEffect(() => {
    if (uniqueId) {
      const uid = extractUid(uniqueId, BB_ACCESS_CODE_PREFIX)
      setUid(uid)
    }
  }, [uniqueId])

  useEffect(() => {
    if (uid && data.length) {
      const response = data.some((item: any) => {
        if (uid === item.accessCode && !isExpired(Number(item.expiry))) {
          return item
        }
      })
      if (!response) {
        navigate('/timeout')
      }
    }
  }, [uid, data])
  return [uid]
}

export const useSlack = (screen: string) => {
  const [loggedInUsername] = useLoggedInUser()
  useEffect(() => {
    if (allowDisallow()) {
      handleWebHook({
        text: `just opened up ${screen} screen...`,
        username: loggedInUsername || 'Someone',
      })
    }
  }, [loggedInUsername])
}

/* Hook for returning bitly link on component load */
export const useBitly = (bloomBUrl: string) => {
  const globalStateContext = useContext<any>(GlobalStateContext)
  const dispatch = useContext<any>(UserDispatchContext)
  const { bBitly } = globalStateContext

  const [bitlink, setBitlink] = useState(bloomBUrl)
  useEffect(() => {
    if (bloomBUrl) {
      if (!bBitly) {
        dispatch({ type: 'SET_BITLY', payload: {} })
      } else {
        const init = async () => {
          let result
          try {
            result = await bBitly.shorten(bloomBUrl)
            console.log('BLOGGG', result)
            setBitlink(result.link)
          } catch (e) {
            throw e
          }
          return result
        }
        init()
      }
    }
  }, [bBitly, bloomBUrl])
  return [bitlink, bBitly]
}

export const useFooterBitlys = (social: any) => {
  const [bitlink, bBitly] = useBitly(BB_BASE_URL)
  const [bitlys, setBitlys] = useState({})
  useEffect(() => {
    if (bBitly) {
      const fetchBitly = async () => {
        const twitterBitly = await getBitlyUrl(
          'https://twitter.com/' + social.twitter,
          bBitly
        )
        const facebookBitly = await getBitlyUrl(
          `https://www.facebook.com/${social.facebook}`,
          bBitly
        )
        const instagramBitly = await getBitlyUrl(
          `https://www.instagram.com/${social.instagram}`,
          bBitly
        )
        const youtubeBitly = await getBitlyUrl(
          `https://www.youtube.com/channel/${social.youtube}`,
          bBitly
        )
        setBitlys({
          twitterBitly,
          facebookBitly,
          instagramBitly,
          youtubeBitly,
        })
      }
      fetchBitly()
    }
  }, [bBitly])
  return [bitlys]
}

export const useAuthor = (filteredData: any, requestType: string) => {
  const [author, setAuthor] = useState(BB_ADMIN_USERNAME)
  console.log('AUTHOR', author)
  useEffect(() => {
    if (filteredData && filteredData.id) {
      if (requestType === 'appointments') {
        console.log('AUTHOR setting ', filteredData.parentKey)

        setAuthor(filteredData.parentKey)
      } else if (requestType === 'medicineOnlyRequests') {
        setAuthor(filteredData.updatedBy)
      }
    }
  }, [filteredData])
  return [author]
}
